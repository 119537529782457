@import "../fonts/georgia-2-cufonfonts-webfont/style.css";
@import "../fonts/segoe-ui-4-cufonfonts-webfont/style.css";

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  background-color: #fafafa;
  position: relative;
  min-height: 100%;
}