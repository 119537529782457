/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Georgia Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Regular'), url('georgia.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Italic'), url('georgiai.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Bold'), url('georgiab.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Georgia Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Georgia Bold Italic'), url('georgiaz.woff') format('woff');
    }